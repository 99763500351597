<template>
    <div>
        <section id="snippet-11" class="wrapper bg-light wrapper-border">
            <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
                <div class="row">
                    <!--/column -->
                    <div class="col-lg-12">
                        <h2 class="display-5 mb-10">Tugas Pokok dan Fungsi</h2>
                        <p v-html="uraian"></p>
                    </div>
                    <!--/column -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    const ADD_URL = URL_APP + "api/v1/publish_dpmptsp_tugas_pokok/";
    const FILE_LOAD = URL_APP + "uploads/";


    export default {

        data() {
            return {
                file_path: FILE_LOAD,
                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
                uraian: "",
            }
        },
        methods: {
        getView: function() {
            
            fetch(ADD_URL + "view", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                },
            })
            .then(res => res.json())
            .then(res_data => {
                var bidang_penataan_dan_peningkatan = res_data[0];
                this.uraian = bidang_penataan_dan_peningkatan.uraian;
                // console.log(res_data);                
            });
        },


    // ================== PAGINASI ====================
        alertku: function(type, title) {
            this.$swal({
                type: type,
                title: title,
                showConfirmButton: false,
                timer: 800
            });
        },

        btn_prev : function(){
                this.cek_load_data = true;
            if(this.page_first>1){

                this.page_first--
            }else{

                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            
            if(this.page_first >= this.page_last){

                this.page_first == this.page_last
            }else{

                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){

            this.page_first = 1;
            this.getView();
        },

        convert_tgl: function(dateString) {

            var date = new Date(dateString);
            return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
        }
    // ================== PAGINASI ====================
    },
    // =============================================================== METHODS =========================================================================

    mounted() {

        this.getView();}
    };
</script>